// 
// root.scss
//

@mixin color-mode($mode: light, $root: false) {
    @if $color-mode-type =="media-query" {
        @if $root ==true {
            @media (prefers-color-scheme: $mode) {
                :root {
                    @content;
                }
            }
        }

        @else {
            @media (prefers-color-scheme: $mode) {
                @content;
            }
        }
    }

    @else {
        [data-bs-theme="#{$mode}"] {
            @content;
        }
    }
}

//theme-light
:root,
[data-bs-theme="light"] {
    --#{$prefix}header-bg:                                var(--#{$prefix}white);
    --#{$prefix}header-item-color:                        #{$header-item-color};
    --#{$prefix}header-item-sub-color:                    #{$text-muted};
    --#{$prefix}sidebar-bg:                               #{$white};
    // Topbar User
    --#{$prefix}topbar-user-bg:                           #{$gray-100};

    // Horizontal nav
    --#{$prefix}topnav-bg:                                #{$white};
    --#{$prefix}topnav-item-color:                        #{darken($gray-600, 10%)};
    --#{$prefix}topnav-item-color-active:                 var(--#{$prefix}primary);

      // twocolumn menu
    --#{$prefix}twocolumn-menu-iconview-bg:               #{$white};
    --#{$prefix}twocolumn-menu-bg:                        #{$white};

    --#{$prefix}text-muted:                               #{$gray-600};
    --#{$prefix}alert-border-color:                       #{$gray-200};
    --#{$prefix}list-group-color:                         #{$gray-700};

    --#{$prefix}toast-background-color:                   #{$white};
    --#{$prefix}toast-border-color:                       #{$gray-300};

    --#{$prefix}input-bg:                                 #{$white};
    --#{$prefix}input-focus-border-color:                 #{$blue-200};
    --#{$prefix}input-border-color:                       #{$gray-400};

    --#{$prefix}menu-item-color:                          #7b8190;

     // Display
    --#{$prefix}display-none:                                  none;
    --#{$prefix}display-block:                                 block;

    &[data-sidebar="brand"] {
        --#{$prefix}vertical-menu-bg:                         #{$blue-700};
        --#{$prefix}vertical-menu-border:                     #{$blue-700};
        --#{$prefix}vertical-menu-item-color:                 #a5bad9;
        --#{$prefix}vertical-menu-item-hover-color:           #{$white};
        --#{$prefix}vertical-menu-item-hover-bg:              #1c2932;
        --#{$prefix}vertical-menu-item-active-color:          #{$white};
        --#{$prefix}vertical-menu-sub-item-color:             #a5bad9;
        --#{$prefix}vertical-menu-sub-item-hover-color:       #{$white};
        --#{$prefix}vertical-menu-sub-item-active-color:      #{$white};
        --#{$prefix}vertical-menu-title-color:                #a5bad9;
    }
}

[data-topbar="dark"] {
    --#{$prefix}header-bg:                            #2f374e;
    --#{$prefix}header-item-color:                    #{$gray-100};
    --#{$prefix}header-item-sub-color:                #8795ab;
}

[data-sidebar="dark"] {
    --#{$prefix}sidebar-bg:                                  #1c2742;
}

//theme dark
@if $enable-dark-mode {
    @include color-mode(dark, true) {
        --#{$prefix}white:                      #{$white-dark};
        --#{$prefix}white-rgb:                  #{to-rgb($white-dark)};
        --#{$prefix}light:                      #{$light-dark};
        --#{$prefix}light-rgb:                  #{to-rgb($light-dark)};
        --#{$prefix}dark:                       #{$dark-dark};
        --#{$prefix}dark-rgb:                   #{to-rgb($dark-dark)};
        --#{$prefix}display-none:               #{$display-none-dark};
        --#{$prefix}display-block:              #{$display-block-dark};
        --#{$prefix}body-bg:                    #{$body-bg-dark};
        --#{$prefix}sidebar-bg:                 #{$sidebar-bg-dark};
        --#{$prefix}text-muted:                 #{$text-muted-dark};
        --#{$prefix}alert-border-color:         #{$alert-border-color-dark};
        --#{$prefix}card-color:                 #{$card-color-dark};
        --#{$prefix}list-group-color:           #{$list-group-color-dark};
        --#{$prefix}toast-background-color:     #{$toast-background-color-dark};
        --#{$prefix}toast-border-color:         #{$toast-border-color-dark};
        --#{$prefix}input-bg:                   #{$input-bg-dark};
        --#{$prefix}input-focus-border-color:   #{$input-focus-border-color-dark};
        --#{$prefix}input-border-color:         #{$input-border-color-dark};
        --#{$prefix}header-bg:                  #{$header-bg-dark};
        --#{$prefix}header-item-color:          #{$header-item-color-dark};
        --#{$prefix}menu-item-color:            #{$menu-item-color-dark};
        --#{$prefix}topnav-bg:                  #{$topnav-bg-dark};
        
        &[data-sidebar="dark"]{
            --#{$prefix}sidebar-bg:              #{$sidebar-bg-dark};
        }
    }
}
